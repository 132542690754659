<template>
    <div class="container grid">
      <Toast/>
      <Toolbar class="lg:col-6 md:col-12 sm:col-12 mb-2 mt-1">
        <template #start>
          <div class="actions">
            <Button v-if="devices.length > 1 && enableCamera" icon="pi pi-sync" @click="onSwitchCamera" class="mr-1"/>
            <ToggleButton v-model="enableCamera" 
              onLabel="Note" 
              onIcon="pi pi-pencil p-button-primary" 
              offIcon="pi pi-camera" 
              offLabel="Scan"
              v-if="resource.length > 0"/>
          </div>
        </template>
        <template #center>
          <div class="qrcode" >
            <span style="font-size: 1.25rem;">{{ resource[1] }}</span>
            <div class="scannedTime">{{scannedLabel}}</div>
          </div>
        </template>
        <template #end>
            <Button v-if="enableCamera && entity != null" icon="pi pi-camera" @click="onCapture" class="mr-2"/>
            <Button v-if="enableCamera && storeable" icon="pi pi-save" @click="onStorePackage"/>
            <Button v-if="!enableCamera && entity != null" icon="pi pi-save" @click="onSaveNote"/>
        </template>
      </Toolbar>
      <div class="lg:col-6 md:col-12">{{message}}</div>
      <div class="lg:col-6 md:col-12">
        <camera-stream ref="camera"  v-show="enableCamera" :camera="camera" @cameras="onCameras" @decode="onDetect"/>
        <div class="card p-fluid grid" v-show="!enableCamera">
          <span class="col-12 p-float-label">
            <Textarea id="note" type="text" v-model="note" rows="5"/>
            <label for="note">{{$t('camera.note')}}</label>
          </span>
          <div class="col-6">
            <ToggleButton v-for="item in storeStatus.filter((item, ind) => ind % 2 == 0)" class="mb-1"
              :key="item.value"
              v-model="status[item.value]"
              :onLabel = "item.label"
              :offLabel = "item.label"/>
          </div>
          <div class="col-6">
            <ToggleButton v-for="item in storeStatus.filter((item, ind) => ind % 2 == 1)" class="mb-1"
              :key="item.value"
              v-model="status[item.value]"
              :onLabel = "item.label"
              :offLabel = "item.label"/>
          </div>
          <!-- <div class="field-checkbox mb-0">
            <Checkbox id="abnormal" v-model="abnormal" :binary="true" />
            <label for="abnormal">{{$t('camera.abnormal')}}</label>
          </div> -->
        </div>
      </div>
    </div>
</template>

<script>
import CameraStream from '@/components/camera/CameraStream';
import {fromBase64, extractResource} from '@/utils/common'
import ImageService from '@/service/ImageService'
import {Service} from '@/service/common'
import authHeader from '@/service/auth.header'
import formatter from '@/utils/formatter';
import PackageService from '@/service/PackageService';
const STORE_STATUSES = [
  {
    value: "Value1",
    label: "Label 1"
  },
  {
    value: "Value2",
    label: "Label 2"
  },
  {
    value: "Value3",
    label: "Label 3"
  },
  {
    value: "Value4",
    label: "Label 4"
  },
  {
    value: "Value5",
    label: "Label 5"
  }
]
export default {
    name: "CameraArrivedPackage",
    components: {CameraStream},
    data() {
        return {
            message: null,
            camera: "auto",
            note: null,
            abnormal: false,
            enableCamera: true,
            deviceId: null,
            devices: [],
            entity: null,
            resource: [],
            scannedTime: null,
            status: {},
            storeable: false,
            storeStatus: STORE_STATUSES,
        };
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        scannedLabel() {
          return formatter.formatTime(this.scannedTime);
        }
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            if (this.devices.length > 0) {
                const first = this.devices[0];
                this.deviceId = first.deviceId;
            }
        }
    },
    methods: {
        onSwitchCamera() {
          if(this.devices.length > 1) {
            if (this.camera == 'auto' || this.camera == 'rear') {
              this.camera = 'front';
            } else if (this.camera == 'front') {
              this.camera = 'rear';
            }
          }
        },
        onCapture() {
            this.message = null;
            if (Array.isArray(this.resource) && this.resource[0] && this.resource[1]) {
              const camera = this.$refs.camera;
              const canvas = camera.getCanvas();
              const ctx = canvas.getContext("2d");
              ctx.font = "30px Georgia";
              ctx.fillStyle = "#FF0000";
              ctx.fillText(this.resource[1], 10, 40);
              canvas.toBlob((blob) => {
                let formValues = {
                  entity: this.resource[0],
                  code: this.resource[1],
                  step: 'stored'
                };
                ImageService.uploadImage(blob, formValues).then((res) => {
                  this.message = this.$t('image.upload_success',[res.data]);
                  camera.clearCanvas(canvas);
                }).catch(() => {
                  this.message = this.$t('image.upload_error');
                });
              })
            } else {
              this.message = this.$t('error.package_missing')
            }
        },
        onStorePackage() {
          this.message = null;
          if (Array.isArray(this.resource) && this.resource[0] == 'package' && this.resource[1]) {
            const data = {
              packageCode: this.resource[1],
            }
            PackageService.storePackage(data).then((res) => {
              if (res.data && res.data.message) {
                let message = this.$t(res.data.message)
                this.$toast.add({severity:'info', summary: 'Message', detail: message, life: 3000});
              } else {
                this.$toast.add({severity:'info', summary: 'Message', detail: "Cannot store package", life: 3000});
              }
            });
          } else {
            this.message = this.$t('error.package_missing')
          }
        },
        async onSaveNote() {
          if(this.entity && this.entity.id) {
            let storeStatus = STORE_STATUSES.filter(item => this.status[item.value]).map(item => item.value);
            const data = {
              id: this.entity.id,
              storeNote: this.note,
              storeStatus: storeStatus.join(',')
            }
            try {
              let res = await PackageService.update(this.entity.id, data);
              this.$toast.add({severity:'info', summary: 'Message', detail: this.$t('package.note_saved_successfully'), life: 3000});
              console.log(res);
            } catch (e) {
              this.$toast.add({severity:'error', summary: 'Message', detail: "Cannot save package's note", life: 3000});
            }
            //this.worklogService.create(data).then(res => console.log(res))
          }
        },
        async onDetect(decodedString) {
          try {
            if(decodedString.startsWith("http://") || decodedString.startsWith("https://")) {
              const resource = extractResource(decodedString);
              this.resource = resource;
            } else {
              this.resource = ["package", decodedString];
            }         
            this.scannedTime = new Date();
            this.entity = null;
            this.storeable = false;
            if (this.resource[1]) {
              let entities = await PackageService.findByCode(this.resource[1]);
              if (entities.numberOfElements == 1 && Array.isArray(entities.content)) {
                this.entity = entities.content[0];
                if (this.entity.processStatus >= 3) {
                  this.storeable = false;
                  this.$toast.add({severity:'info', summary: 'Message', detail: this.$t('package.already_stored'), life: 3000});
                } else {
                  this.storeable = true;
                }
              }
            }
          } catch(e) {
            this.scannedTime = null;
            console.log(e)
          }
        },
        /**
         * load available cameras
         */
        loadCameras() {
          try {
            const mediaDevices = navigator.mediaDevices;
            if(mediaDevices) {
                mediaDevices.enumerateDevices()
                .then(deviceInfos => {
                  alert(deviceInfos.length);
                  for (let i = 0; i !== deviceInfos.length; ++i) {
                    let deviceInfo = deviceInfos[i];
                    alert(deviceInfo.kind + ':' + deviceInfo.label);
                  }
                  this.devices = deviceInfos.filter(({kind}) => kind === 'videoinput')
                  console.log(this.devices);
                  })
                .catch(error => {
                  alert(error)
                  this.$emit("notsupported", error)
                });
              }
          } catch (err) {
            console.log(err);
          }
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        beforeSendFile(event) {
          const headers = authHeader();
          event.xhr.setRequestHeader('Authorization',headers.Authorization);
          console.log(event.formData);
          console.log(event.xhr);
        }
    },
    mounted() {
      this.worklogService = new Service('worklog');
      if(this.$route.params.resource) {
        this.resource = fromBase64(this.$route.params.resource).split('|');
      }
    },
    beforeUnmount() {
      if ( this.$refs.camera) {
        this.$refs.camera.stopCamera();
      }
    }
};
</script>
<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
  }
  .container .p-toolbar {
    padding: 0 1rem;
    margin: -0.5rem 0;
    border: 0;
    font-weight: 600;
  }
  .container .qrcode {
    margin-bottom: 2px;
  }
  .qrcode .scannedTime {
    font-size: 1rem;
    font-weight: normal;
    font-style:italic;
  }
  .actions {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
</style>
